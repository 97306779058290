@use '@/styles/global' as g;

.hero {
    padding-bottom: 24px;
    z-index: 3;
    transform: translate3d(0, 0, 0);

    @include g.bp(md) {
        padding-bottom: 64px;
        text-align: center;
    }

    .heroHeader {
        margin-top: 40px;
        margin-bottom: 4px;

        @include g.bp(md) {
            margin-top: 44px;
            margin-bottom: 8px;
        }
    }
}

.table {
    width: 100%;
    position: relative;
    display: block;

    @include g.bp-max(md) {
        width: 100vw;
        margin-left: -18px;
    }

    @include g.bp-max(sm) {
        width: 100vw;
        margin-left: -16px;
    }

    thead,
    tbody {
        display: block;
    }

    thead {
        background: var(--white);
        position: sticky;
        top: 0;
        z-index: 2;
        margin-top: -46px;

        @include g.bp(md) {
            position: unset;
            margin-top: 0;
        }
    }

    &.leftAlign {

        th,
        td {
            justify-content: left;
            align-items: flex-start;
            text-align: left;
        }
    }

    &.spaciousVariant {
        margin: 0 16px;

        tbody tr {
            height: auto;
            justify-content: space-between;
            border-bottom: 1px solid g.$gray-light;
            padding-left: 0;
            gap: 8px;
        }

        th {
            padding-top: 0;
            padding-bottom: 8px;
            @include g.font-size(14px);
            @include g.set-font-weight('bold');

            &:first-of-type {
                padding-left: 0;
            }
        }

        tbody tr td {
            padding: 0 24px 8px;
            font-weight: normal;
            @include g.font-size(14px);
            text-transform: none;

            &:first-of-type {
                padding-left: 0;
            }

            svg path {
                stroke: g.$purple !important; // Overriding an inline style
                @include g.web2PageOverrides {
                    stroke: var(--base-contrast, #202020) !important;
                }
            }

            .dash:before {
                content: '';
                width: 24px;
                height: 4px;
                margin-top: 2px;
                position: absolute;
                background-color: g.$gray-medium;
            }
        }

        .tooltip button {
            padding: 0;
        }

        .tooltipContent {
            @include g.set-font-weight('book');
        }

        @include g.bp(md) {

            tbody tr td,
            th {
                padding-top: 24px;
                padding-bottom: 24px;
                align-items: center;

                &:first-of-type {
                    padding-left: unset;
                }
            }
        }

        @include g.bp(lg) {
            tbody tr {
                justify-content: flex-start;
            }

            tbody tr td,
            th {
                padding-top: 24px;
                padding-bottom: 24px;

                &:first-of-type {
                    padding-left: unset;
                }
            }

            th {
                width: 42%;
            }

            tbody tr td {
                max-width: 100%;
            }
        }
    }

    .bestPlan {
        position: absolute;
        background: g.$raspberry;
        color: g.$white;
        justify-content: center;
        align-items: center;
        display: flex;
        height: 26px;
        width: 100%;
        @include g.font-size(12px);
        @include g.set-font-weight('bold');
        top: 0;
        left: 0;
        z-index: 4;

        @include g.bp(md) {
            top: -26px;
            border-radius: 8px 8px 0px 0px;
        }
    }

    .topRowMobile {
        padding: 12px 8px;

        th p {
            @include g.font-size(12px);

            a {
                color: var(--black);
            }
        }

        @include g.bp(md) {
            display: none;
        }
    }

    tr {
        border-bottom: 1px solid g.$blue-ultra-light;
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
        padding: 16px 8px;
        row-gap: 8px;

        &:not(.rowHead, .rowPrice) {
            @include g.bp(md) {
                align-items: center;
                height: 48px;
            }
        }

        &:not(.rowHead, .rowPrice, .rowChannels) {
            td {
                @include g.set-font-weight('bold');
                @include g.font-size(14px);

                @include g.bp(md) {
                    text-transform: uppercase;
                    @include g.font-size(12px);
                }
            }
        }

        @include g.bp(md) {
            flex-wrap: nowrap;
            padding: 0;

            //TODO: add new color??
            border-bottom: 1px solid #d9d9d9;
        }

        .mobileTh {

            @include g.bp(md) {
                display: none;
            }
        }

        .desktopTh {
            display: none;

            @include g.bp(md) {
                display: flex;
            }
        }

        th {
            display: block;
            @include g.set-font-weight('book');
            text-align: center;
            width: 100%;
            @include g.font-size(14px);
            position: relative;

            @include g.bp(md) {
                width: 40%;
                text-align: left;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &.bestPlanCol {
                height: 100%;
                background: g.$blue-ultra-light;
            }
        }

        td {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
            width: 40%;
            text-align: center;

            @include g.bp(md) {
                width: 20%;
                max-width: 20%;

                &.bestPlanCol {
                    height: 100%;
                    background: g.$blue-ultra-light;
                }
            }

            svg {
                width: 24px;
            }
        }

        &.rowHead {
            border-bottom: none;
            padding: 0;
            align-items: center;

            th {
                @include g.set-font-weight('book');
                line-height: 1.25;
                @include g.font-size(12px);
                width: 40%;
                text-align: center;
                border-right: 1px solid g.$blue-ultra-light;
                flex: 1;
                padding-top: 32px;

                &:last-child {
                    border-right: none;
                }

                @include g.bp(md) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 20%;
                    max-width: 20%;
                    padding: 8px 3.5% 8px;
                    @include g.set-font-weight('bold');
                    @include g.font-size(14px);
                    border: none;
                }

                &:first-child {
                    display: none;

                    .showMobile {
                        display: none;
                    }

                    @include g.bp(md) {
                        display: block;
                        width: 40%;
                        max-width: 40%;

                        span {
                            position: absolute !important;
                            width: 1px !important;
                            height: 1px !important;
                            padding: 0 !important;
                            overflow: hidden !important;
                            clip: rect(0, 0, 0, 0) !important;
                            white-space: nowrap !important;
                            border: 0 !important;
                        }
                    }
                }

                &:nth-child(2) {
                    @include g.bp(md) {
                        height: 51px;
                    }
                }

                .planHeaderWrapper {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        &.rowPrice {
            background: var(--white);
            padding: 0;
            position: sticky;
            top: 93px;
            z-index: 2;

            @include g.bp(md) {
                position: unset;
            }

            th {
                order: 3;
                display: none;
                flex-direction: column;
                justify-content: space-around;
                padding-bottom: 16px;

                @include g.bp(md) {
                    order: 0;
                    padding-bottom: 0;
                    align-items: flex-start;
                    display: flex;
                }

                p {
                    @include g.font-size(12px);
                    padding-top: 4px;

                    @include g.bp(md) {
                        padding-top: 0;
                        @include g.font-size(14px);
                    }

                    a {
                        color: g.$black;
                    }
                }

                .priceHeader {
                    display: none;
                    text-align: left;
                    @include g.font-size(24px);
                    @include g.set-font-weight('bold');
                    line-height: 1.1;

                    @include g.bp(md) {
                        display: block;
                    }
                }
            }

            td {
                display: flex;
                flex-direction: column-reverse;
                padding: 0 0 12px;
                row-gap: 12px;
                border-bottom: 1px solid g.$blue-ultra-light;
                border-right: 1px solid g.$blue-ultra-light;

                &:last-child {
                    border-right: none;
                }

                &.bestPlanCol {
                    background: g.$blue-ultra-light;
                }

                @include g.bp(md) {
                    flex-direction: column;
                    row-gap: 16px;
                    border: none;
                }
            }

            .colPrice {
                @include g.font-size(12px);
                line-height: 1.25;
                padding-top: 4px;

                strong {
                    display: block;
                    @include g.set-font-weight('black');
                    @include g.font-size(16px);
                    line-height: 1.1;
                    max-width: 76px;
                    margin: 0 auto;

                    @include g.bp(md) {
                        line-height: 1.25;
                        max-width: unset;
                    }
                }

                @include g.bp(md) {
                    flex-direction: column;
                    padding-top: 0;
                }
            }
        }

        &.rowChannels {
            @include g.bp(md) {
                padding: 0;
            }

            p {
                @include g.font-size(12px);
                @include g.set-font-weight('bold');
                line-height: 1.5;

                strong {
                    display: block;
                    color: g.$blue-secondary;
                    @include g.font-size(14px);

                    @include g.bp(md) {
                        @include g.font-size(12px);
                    }
                }

                @include g.bp(md) {
                    @include g.set-font-weight('book');
                }
            }
        }
    }

    .tooltip {
        display: inline-block;
        margin-right: 8px;
    }

    .tooltipContent {
        min-width: 200px;
    }

    .showMobile {
        display: inline;

        @include g.bp(md) {
            display: none;
        }
    }

    .showDesktop {
        display: none;

        @include g.bp(md) {
            display: inline;
        }
    }

    .totalLink {
        text-decoration: none;
    }
}

.tabsWrapper {
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 358px;
    height: 56px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    padding: 6px;
    margin-top: 24px;
    position: relative;

    @include g.bp(sm) {
        width: 358px;
    }

    @include g.bp(md) {
        margin-top: 16px;
    }

    .tabAnimatedSwitch {
        border-radius: 30px;
        background-color: var(--white);
        position: absolute;
        height: 44px;
        width: 100%;
        transition: all 0.25s;
        left: 6px;
    }

    button {
        @include g.font-size(12px);
        @include g.set-font-weight('book');
        padding: 0 8px;
        border-radius: 30px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 2;

        @include g.bp(sm) {
            padding: 0 12px;
        }

        svg {
            fill: var(--white);
            width: 28px;
            height: 28px;

            &:last-of-type {
                margin-right: 8px;
            }
        }

        &.buttonActive {
            color: var(--black);
            // background-color: var(--white);

            svg {
                fill: var(--black);
            }

            &:first-child~.tabAnimatedSwitch {
                width: 52%;
            }

            &:nth-child(2)~.tabAnimatedSwitch {
                width: 44%;
                left: 54.5%;
            }
        }
    }
}

.gridWrapper {
    $animationDuration: 0.3s;
    overflow-x: clip;
    width: 100%;

    .grids {
        width: 200%;
        display: flex;
        flex-direction: row;
        margin-left: 0;
        transition: margin-left ease-in $animationDuration;

        @include g.bp(md) {
            transition: none;
        }

        &.showRightItem {
            margin-left: -100%;
        }

        .gridItem {
            width: 50%;
            visibility: hidden;
            transition: visibility $animationDuration;

            @include g.bp(md) {
                transition: none;
            }

            &.active {
                visibility: visible;
            }
        }
    }
}