@use '@/styles/global' as g;

.mainWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 756px;
    padding: 16px 27px 12px 24px;
    border-radius: 16px;
    margin: 0 auto;
    gap: 8px;
    background-color: g.$secondary-ultra-light-blue;

    @include g.bp-max(lg) {
        flex-direction: column;
        align-items: start;
        padding: 16px 44.5px 19px 24px;
    }

    @include g.bp-max(md) {
        flex-direction: column;
        border-radius: 12px;
        padding: 16px 17px;
    }

    .iconAndText {
        display: flex;
        gap: 23px;

        .icon {
            width: 70px;
            height: 70px;

            @include g.bp-max(md) {
                display: none;
            }
        }

        .textContentWrapper {
            display: flex;
            flex-direction: column;

            .headingWrapper {
                display: flex;
                align-items: center;
                gap: 12px;

                .headiline {
                    @include g.font-size(20px);
                    font-weight: 900;
                    line-height: 1.2;

                    @include g.web2PageOverrides {
                        color: var(--base-contrast, #202020)
                    }

                    @include g.bp-max(sm) {
                        @include g.font-size(18px);
                    }
                }

                .mobileIcon {
                    display: none;

                    @include g.bp-max(md) {
                        display: block;
                        width: 56px;
                        height: 56px;
                    }
                }
            }

            .descriptionText {
                @include g.font-size(14px);
                @include g.set-font-weight('book');
                line-height: 1.57;

                @include g.web2PageOverrides {
                    color: var(--base-contrast, #202020)
                }

                @include g.bp-max(sm) {
                    @include g.font-size(12px);
                    line-height: 1.5;
                }

                .descriptionPrice {
                    @include g.set-font-weight('bold');
                    color: g.$raspberry;

                    @include g.web2PageOverrides {
                        color: var(--base-contrast, #202020);
                    }

                    @include g.bp-max(sm) {
                        @include g.font-size(12px);
                        line-height: 1.5;
                    }
                }

                .descriptionOffer {
                    @include g.set-font-weight('bold');
                    text-decoration: underline;
                    color: inherit;

                    @include g.bp-max(sm) {
                        @include g.font-size(12px);
                        line-height: 1.5;
                    }
                }
            }
        }
    }

    .buttonWrapper {
        width: 186px;

        @include g.bp-max(lg) {
            margin-left: 85px;
        }

        @include g.bp-max(md) {
            width: 100%;
            margin-left: 0;
        }

        .button {
            @include g.font-size(12px);
            @include g.set-font-weight('bold');
            line-height: 1.5;
            padding: 13px 20px;
            width: 186px;
            margin-top: 0;
            text-transform: none;

            @include g.bp-max(md) {
                width: 100%;
            }
        }
    }
}