@use '@/styles/global' as g;

.mainContentWrapperStyles {
    width: 100%;
    background-image: var(--mobile-background);

    @include g.bp(lg) {
        padding: 24px 0px;
        background-image: var(--desktop-background);
    }
}

// We use !important to override the global grid-container styles to have the Image taken the full width.
.containerStyles {
    padding-left: 0px !important;
    padding-right: 0px !important;

    @include g.bp(sm) {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    @include g.bp(md) {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    @include g.bp(lg) {
        padding-left: 56px !important;
        padding-right: 56px !important;
    }
}
.lightTheme {
    display: flex;
    flex-direction: column;
    background-color: transparent;

    .headlineStyles {
        span {
            color: g.$black;
        }
    }
    .bodyStyles {
        color: g.$black;
    }

    @include g.bp(md) {
        flex-direction: row;
    }
}
.headlineStyles {
    span {
        color: g.$white;
    }
}
.bodyStyles {
    color: g.$white;
    margin-top: 16px;
}
.spacingStyles {
    padding-bottom: 24px;
    padding-left: 16px !important;
    padding-right: 16px;

    @include g.bp(lg) {
        padding-left: 0px;
        padding-right: 0px;
    }
}
.mediaElementSingleStyles {
    margin-bottom: 12px;

    @include g.bp(lg) {
        margin-bottom: 0;
    }
}
.modalHeadlineStyles {
    margin-bottom: 8px;
}
.modalBodyStyles {
    @include g.font-size(12px);
    word-wrap: break-word;
}
