@use '@/styles/global' as g;

.container {
    padding-top: 30px;

    .frequencyFinderWrapper {
        background-color: g.$blue-light;
        padding: 24px;
        width: 100%;
        margin: 36px 0px;

        .formInfoText {
            margin-bottom: 32px;
        }

        .hasFrequencyBox {
            margin-top: 40px;

            .list {
                padding: 0px;
                margin-top: 8px;
                display: flex;
                flex-wrap: wrap;
                @include g.font-size(20px);
                line-height: 23px;
                list-style-type: decimal;
                list-style-position: inside;

                li {
                    flex: 0 1 50%;
                    margin: 8px 0px;

                    @include g.bp(md) {
                        flex: 0 1 30%;
                    }

                    span {
                        @include g.set-font-weight('bold');
                    }
                }
            }
        }
    }

    .links {
        padding: 0px;

        li {
            list-style: none;
            margin: 16px 0px;
            font-weight: 700;
        }
    }
}