@use '@/styles/global' as g;

.sectionHeader {
    padding-bottom: 48px;

    h3 {
        @include g.font-size(27px);
        line-height: 1.12;
        @include g.set-font-weight('black');

        @include g.bp(md) {
            @include g.font-size(30px);
        }

        @include g.bp(lg) {
            @include g.font-size(32px);
        }
    }

    p {
        margin-top: 8px;
    }
}

.tableHeader {
    height: 55px;
    box-shadow: g.$active-shadow;
    padding-left: 16px;
    display: flex;
    align-items: center;
    @include g.font-size(24px);
    @include g.set-font-weight('black');
    white-space: nowrap;
    z-index: 2;

    @include g.bp(sm) {
        height: 72px;
    }

    @include g.bp(md) {
        box-shadow: none;
        width: 41%;
        @include g.font-size(26px);
    }

    @include g.bp(lg) {
        @include g.font-size(28px);
    }
}

.tooltipContent {
    @include g.set-font-weight('book');
    white-space: normal;
}

.selectWrapper {
    display: flex;
    justify-content: center;
    align-content: center;

    @include g.bp(md) {
        width: 59%;
    }
}

.selectWrapper .selectGroup {
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    display: flex;

    @include g.bp(sm) {
        flex-direction: row;
    }

    @include g.bp(md) {
        flex-direction: column;
        align-items: flex-start;
    }

    @include g.bp(lg) {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.overrideText {
    width: 100%;
    max-width: 380px; // Matches Select Component
    padding: 0 0 0 16px;
    white-space: nowrap;
    text-align: center;
    @include g.set-font-weight('bold');
}

// Strong specificity is needed to override the width of Select's theme in deployed app only:
.contentWrapper .selectWrapper .selectGroup .selectColumn,
.overrideText {
    display: flex;
    align-items: center;

    height: 43px;
    margin: 0;

    &,
    >div {
        max-width: 100%;
    }

    button {
        border-right: none;
    }

    @include g.bp(sm) {
        height: unset;
    }

    @include g.bp(md) {
        height: 36px;
        width: 100%;
        margin: 0;

        @include g.web2PageOverrides {
            margin: 2px;
        }

        &,
        >div {
            max-width: 380px;
        }
    }

    @include g.bp(lg) {
        width: 50%;
        height: 100%;

        @include g.web2PageOverrides {
            width: 48%;
            height: 60%;
        }

        &,
        >div,
        button {
            max-width: 100%;
        }
    }
}

.tableHeaderWrapper.contentWrapper {
    z-index: 2;
    display: flex;
    flex-direction: column;

    @include g.bp(md) {
        flex-direction: row;
    }
}

.tableWrapper.contentWrapper {
    display: flex;
    padding-top: 16px;
    margin-top: 4px;
    z-index: 1;

    @include g.bp(md) {
        margin-bottom: 4px;
        padding-top: 0;
        padding-left: 0;
    }

    @include g.bp(lg) {
        margin-bottom: 4px;
    }
}