@use '@/styles/global' as g;

.wrapper {
    header {
        @include g.bp(md) {
            position: relative;
            z-index: 11;
        }
    }

    main {
        padding-top: 64px;
    }

    h2 {
        @include g.bp-max(md) {
            @include g.font-size(30px);
            line-height: 1.13;
        }
    }

    .trialEligibilityTab {
        min-height: 62vw;

        @include g.bp(md) {
            min-height: 51vw;
        }

        @include g.bp(lg) {
            min-height: 19vw;
        }

        @include g.bp(xl) {
            min-height: 244px;
        }
    }
}