@use '@/styles/global' as g;

.mainWrapper {
    width: 100%;
    padding: 0px 16px;

    @include g.bp(sm) {
        padding: 0px;
    }
}

.selectorWrapper {
    width: 100%;
    max-width: 330px;
    margin: 32px auto;

    @include g.bp(md) {
        width: 440px;
        max-width: 440px;
        margin: 32px auto;
    }

    .headlineStyles {
        span {
            @include g.font-size(16px);
            line-height: 1.5;
            @include g.set-font-weight('bold');
            margin: 0px;
        }
    }

    .errorMessageStyles {
        display: flex;
        width: 100%;
        height: 34px;
        margin-top: 4px;

        svg {
            margin-right: 4px;
        }
    }

    .planCardWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2px;

        .inputWrapper {
            width: 100%;
            position: relative;
            border-radius: 20px;
            border: 3px solid transparent;
        }
    }

    .offerSection {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-top: 32px;

        .textContentWrapper {
            min-height: 66px;
            text-align: center;
        }

        .offerTitleStyles {
            p {
                color: g.$raspberry;
                @include g.font-size(18px);
                @include g.set-font-weight('black');
                line-height: 1.2;
            }
        }

        .offerPriceStyles {
            min-height: 44px;
            @include g.font-size(14px);
            line-height: 1.6;
            text-align: center;

            .offerDetailStyles {
                @include g.set-font-weight('bold');
            }
        }

        .selectButtonStyles {
            margin-top: 12px;
            width: 100%;
            max-width: none;
        }
    }

    .inputStyles {
        position: absolute;
        appearance: none;
        width: 100%;
        height: 100%;
        left: 0;
        border-radius: 20px;
        cursor: pointer;
        z-index: 1;

        &:active,
        &:checked,
        &:focus {
            border: 2px solid g.$secondary-atlas-web-blue;
            outline: none;
        }

        @include g.bp(md) {
            &:hover {
                border: 3px solid g.$secondary-atlas-web-blue;
                outline: none;
            }

            &:active,
            &:checked,
            &:focus {
                border: 2px solid g.$secondary-atlas-web-blue;
                outline: none;
            }
        }
    }
}