@use '@/styles/global' as g;

.planCards {
    display: flex;
    flex-grow: 1;
    gap: g.$grid-column-gap;
    flex-wrap: wrap;

    @include g.bp(md) {
        flex-wrap: nowrap;
        padding-top: 31px;
    }

    >div {
        width: 100%;
    }

    &.inHero {
        justify-content: center;
        padding-top: 48px;

        @include g.bp(md) {
            align-content: center;
            margin-top: -70px;
        }

        >div {
            max-width: 366px;
        }
    }
}