@use '@/styles/global' as g;

.planDetails {
    .medDesc {
        margin-bottom: 2px;

        &.highlighted,
        &.highlighted * {
            color: var(--raspberry);
        }
    }

    .mediumDescSmall {
        @include g.font-size(12px);
        @include g.set-font-weight('book');
        line-height: 18px;
    }

    .mediumDescriptionHeadline {
        @include g.font-size(14px);
        margin-bottom: 2px;

        span {
            @include g.font-size(32px);
            line-height: 36px;
            @include g.set-font-weight('black');
        }
    }

    .shortDesc {
        @include g.set-font-weight('bold');
        margin-bottom: 2px;
    }

    &.narrow {
        max-width: 366px;
    }
}