@use '@/styles/global' as g;

.benefits_container {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include g.bp(md) {
        flex-direction: row;
        margin-bottom: 0;
        margin-top: 2rem
    }

    .benefits_left {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: stretch;

        .title_container {
            display: flex;
            flex-direction: column;
            align-self: stretch;
            padding: 0 2rem;

            @include g.bp(md) {
                padding-left: min(7.5vw, 108px);
            }

            .upper_title {
                @include g.font-size(24px);
                @include g.set-font-weight('black');
                line-height: 24px;
                color: g.$black;
                text-transform: uppercase;

                @include g.bp(md) {
                    @include g.font-size(48px);
                    line-height: 48px;
                }
            }

            .lower_title_container {
                width: 176px;
                height: auto;
                display: flex;
                align-items: center;
                background-color: g.$blue;
                padding: 0px 5px 0 5px;

                @include g.bp(md) {
                    width: 325px;
                    padding: 5px;
                }

                .lower_title {
                    @include g.font-size(34px);
                    @include g.set-font-weight('black');
                    line-height: 40px;
                    color: g.$white;
                    text-transform: uppercase;

                    @include g.bp(md) {
                        @include g.font-size(64px);
                        line-height: 3.5rem;
                    }
                }
            }
        }

        .icon_container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;

            .icon {
                width: 2rem;
                height: 2rem;
                background-color: g.$black;

                @include g.bp(md) {
                    width: min(7.5vw, 108px);
                    height: min(7.5vw, 108px);
                }
            }
        }
    }

    .benefits_right {
        display: flex;
        flex-direction: column;
        gap: 22px;
        align-self: stretch;
        justify-content: center;

        @include g.bp(md) {
            gap: 32px;
            padding-bottom: 0;
        }

        .benefit_item {
            display: flex;
            flex-direction: column;
            align-self: stretch;
            padding-left: 2rem;
            padding-right: 1rem;

            .benefit_content {
                display: flex;
                flex-direction: column;
                gap: 4px;
                align-self: stretch;

                .benefit_name {
                    @include g.font-size(18px);
                    @include g.set-font-weight('black');
                    line-height: 20px;
                    color: g.$blue;
                }

                .benefit_description {
                    @include g.font-size(14px);
                    @include g.set-font-weight('book');
                    color: g.$almost-black;
                }
            }
        }
    }
}
