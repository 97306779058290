@use '@/styles/global' as g;


.capitalizeText {
    text-transform: capitalize;
}

.body {
    display: flex;
    flex-direction: column-reverse;
    gap: 12px;

    @include g.bp(md) {
        flex-direction: row;
        gap: 24px;
    }
}

h3.showTitle {
    margin: 5px 0 16px;
}

.image {
    height: 160px;
    width: 160px;

    @include g.web2PageOverrides {
        border-radius: 24px;
    }
}

.content {
    margin-top: 8px;

    &:not(.show) {
        display: none;
    }
}

.nowPlaying {
    margin-bottom: 8px;
    overflow: hidden;

    .nowPlayingText {
        @include g.font-size(12px);
    }
}