@use '@/styles/global' as g;

.offerIconsList {
    list-style-type: none;
    padding: 0;
    display: flex;
    column-gap: 8px;
    margin-bottom: 0;
    margin-top: 0;

    @include g.web2PageOverrides {
        filter: brightness(0) saturate(100%) invert(8%) sepia(72%) saturate(13%) hue-rotate(314deg) brightness(96%) contrast(92%);
    }

    li {
        height: 48px;
        width: 48px;
    }

    &.desktopWhiteFilter {
        @include g.bp(md) {
            filter: brightness(0) invert(1);
        }
    }

    &.whiteFilter {
        filter: brightness(0) invert(1);
    }
}