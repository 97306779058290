@use '@/styles/global' as g;

.sportsMenu {
    display: flex;
    height: 68px;
    justify-content: space-between;
    align-items: center;

    & &Primary {
        width: 100%;

        @include g.bp(lg) {
            display: flex;
        }

        &List {
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: nowrap;
            list-style: none;

            & > li {
                display: block;
                visibility: hidden;

                button {
                    padding: 26px 20px;
                    white-space: nowrap;
                }

                &.hidden {
                    visibility: hidden;
                    position: absolute;
                }

                &.visibile {
                    visibility: visible;
                }
            }
        }
    }

    & &Secondary {
        width: 100%;

        @include g.bp(lg) {
            width: auto;
        }

        &List {
            margin: 0;
            padding: 0;
            list-style: none;

            & > li {
                display: block;

                &.hidden {
                    display: none;
                }

                a {
                    display: block;
                    padding: 10px 0;
                    color: var(--black);
                    @include g.font-size(14px);
                    text-decoration: none;
                }
            }
        }

        &Popover {
            z-index: 3;
            position: absolute;
            width: 90vw;
            left: 50%;
            transform: translatex(-50%);
            top: 20px;

            @include g.bp(lg) {
                width: max-content;
                right: 0;
                top: 0;
                transform: none;
                left: auto;
            }
        }

        .moreInfo {
            & > button {
                @include g.set-font-weight('book');
                white-space: nowrap;
                width: 100%;
                padding: 20px 0;
                max-width: unset;

                & > div {
                    justify-content: space-between;
                }

                @include g.bp(lg) {
                    padding: 20px;
                }
            }

            &Content {
                display: none;

                & button {
                    margin-bottom: 16px;
                }
            }

            &Active {
                display: block;
            }
        }
    }

    li .subMenuWrapper {
        padding: 0;
        width: auto;
        z-index: 3;
    }

    li .subMenu {
        @include g.web2PageOverrides {
            border-radius: 24px;
            overflow: hidden;
        }
        &.subMenuWithTeam {
            display: flex;
            max-width: 1080px;
            width: 1000vw;
            min-width: 768px;
        }

        &Side {
            background-color: var(--blue);
            color: var(--white);
            width: 245px;
            padding: 20px;

            &List {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    margin: 28px 0;

                    &:first-child {
                        margin-top: 0;
                    }

                    a {
                        @include g.font-size(14px);
                        color: var(--white);
                        @include g.set-font-weight('bold');
                        white-space: normal;
                        text-decoration: none;
                    }
                }
            }
        }

        &Team {
            flex: 1;
            padding: 24px 0 24px 30px;
        }

        &TitleTeam {
            position: unset;

            @include g.bp(xl) {
                position: relative;
            }

            .subMenuWrapper {
                left: 0;
            }
        }
    }
}
