@use '@/styles/global' as g;

.hideItems:not(.showItems) {
    .itemToHide {
        display: none;
    }
}

.showMore {
    row-gap: 16px;
}

.marketingList {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0;
    }

    li {
        display: flex;
        align-items: center;
        column-gap: 12px;
    }

    .shortDescription {
        @include g.set-font-weight('bold');
    }

    .moreInfo {
        @include g.font-size(12px);
    }

    p {
        margin-bottom: 0;
    }

    &.iconBullet {
        li img {
            width: 40px;
            min-width: 40px;
        }
    }

    &.checkmarkBullet {
        row-gap: 8px;

        li {
            align-items: flex-start;

            svg {
                width: 12px;
                min-width: 12px;
                margin-top: 6px;
                stroke: var(--blue-secondary);
                stroke-width: 2px;

                @include g.web2PageOverrides {
                    stroke: var(--base-contrast-accent);
                }
            }
        }
    }

    &.whiteFilter {
        li {
            img {
                filter: brightness(0) invert(1);
            }

            svg {
                stroke: var(--white);
            }
        }
    }

    &.listSmall {
        li p {
            @include g.font-size(12px);
            line-height: 1.25;
        }

        &.checkmarkBullet {
            row-gap: 12px;

            li svg {
                width: 10px;
                min-width: 10px;
                margin-top: 4px;
            }
        }
    }
}
