@use '@/styles/global' as g;

.hero {
    @include g.web2PageOverrides {
        background: var(--base-contrast-accent, #0000eb);
    }

    .heroContainer {
        height: 290px;

        @include g.bp-max(md) {
            height: 272px;
        }

        @include g.bp-max(sm) {
            height: 182px;
        }
    }

    .textContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 78px;

        @include g.bp-max(lg) {
            padding-left: 52px;
        }

        @include g.bp-max(md) {
            padding-left: 0;
        }

        .heroCopy {
            @include g.font-size(48px);
            font-weight: 900;
            line-height: 1.04;
            margin: 0 0 10px;
            max-width: 453px;
            color: var(--subset-sxm-blue-contrast, #ffffff);

            span {
                display: block;
            }

            @include g.bp-max(lg) {
                max-width: 454px;
            }

            @include g.bp-max(md) {
                @include g.font-size(30px);
                line-height: 1.13;
                margin: 0 0 4px;
                max-width: 300px;
            }
        }

        .heroOffer,
        p {
            color: var(--subset-sxm-blue-contrast, #ffffff);
            @include g.font-size(16px);
            @include g.set-font-weight('book');
            line-height: 1.5;

            .price {
                @include g.set-font-weight('bold');
            }

            @include g.bp-max(lg) {
                max-width: 380px;
            }

            @include g.bp-max(md) {
                @include g.font-size(12px);
                max-width: 302px;
            }

            .spanOffer {
                @include g.set-font-weight('bold');
                text-decoration: underline;
                color: g.$white;
            }

            .offerDetailsDesktop {
                display: inline-block;
            }
        }
    }
}

.gridWrapper {
    overflow-x: clip;
    width: 100%;
    max-width: 756px;
    margin: 50px auto 0;
    padding-bottom: 50px;

    @include g.bp-max(lg) {
        max-width: 620px;
    }

    @include g.bp-max(sm) {
        margin: 35px auto 0;
    }

    .grids {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-left: 0;

        .gridContainer {
            width: 100%;
            padding-left: 0;
            padding-right: 0;

            .gridItem {
                display: flex;
                flex-direction: column;
                gap: 27px;

                @include g.bp-max(lg) {
                    padding-left: 10px;
                    padding-right: 10px;
                }

                @include g.bp-max(md) {
                    gap: 24px;
                    padding-left: 10px;
                    padding-right: 10px;
                }

                .table {
                    display: block;
                    width: 100%;
                    max-width: 756px;

                    thead,
                    tbody {
                        display: block;
                    }

                    thead {
                        background: g.$white;
                        margin-top: 10px;
                    }

                    .bestPlan {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        height: 36.34px;
                        @include g.set-font-weight('bold');
                        @include g.font-size(16px);
                        line-height: 1.31;
                        width: 128px;
                        top: -18px;
                        border-radius: 12px;
                        left: 50%;
                        transform: translateX(-50%);
                        background: g.$raspberry;
                        color: g.$white;

                        @include g.web2PageOverrides {
                            color: var(--base-contrast, #202020);
                            background: #ffd217;
                        }

                        @include g.bp-max(lg) {
                            height: 32.94px;
                            top: -16px;
                        }

                        @include g.bp-max(md) {
                            height: 24px;
                            border-radius: 4px;
                            top: -12px;
                            @include g.set-font-weight('book');
                            @include g.font-size(12px);
                            line-height: 1.33;
                            width: 90px;
                        }
                    }

                    tr {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        width: 100%;
                        row-gap: 8px;
                        border-bottom: 1px solid g.$secondary-light-gray;

                        @include g.web2PageOverrides {
                            border-bottom: 1px solid var(--base-border, #dbdbdb);
                        }

                        &:last-child {
                            border: none;

                            &:not(.rowHead, .rowPrice) {

                                td {
                                    border-bottom-left-radius: 16px;
                                    border-bottom-right-radius: 16px;
                                }
                            }
                        }

                        &:first-child {
                            border: none;
                        }

                        &:not(.rowHead, .rowPrice) {
                            align-items: center;
                            height: 110px;
                        }

                        th {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            position: relative;
                            @include g.set-font-weight('bold');
                            @include g.font-size(16px);
                            line-height: 1.31;
                            width: 40%;

                            @include g.web2PageOverrides {
                                color: var(--base-contrast, #202020)
                            }

                            &.smallerAndroidPhone {
                                width: 35%;
                            }

                            &.bestPlanCol {
                                height: 100%;
                                background: g.$off-white;

                                @include g.web2PageOverrides {
                                    background: var(--base-surface, #f7f7f7);
                                }
                            }
                        }

                        td {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex: 1;
                            text-align: center;
                            max-width: 210px;
                            width: 210px;

                            @include g.bp-max(md) {
                                width: 111px;
                                max-width: 111px;
                            }

                            &.bestPlanCol {
                                height: 100%;
                                background: g.$off-white;
                                @include g.font-size(16px);
                                @include g.set-font-weight('book');
                                line-height: 1.31;

                                @include g.web2PageOverrides {
                                    background: var(--base-surface, #f7f7f7);
                                    color: var(--base-contrast, #202020);
                                }

                                @include g.bp-max(md) {
                                    @include g.font-size(12px);
                                }

                                @include g.bp-max(sm) {
                                    padding: 0 17px;
                                }
                            }

                            svg {
                                width: 16px;
                                min-width: 16px;
                                height: 16px;
                                stroke: var(--blue-secondary);
                                stroke-width: 2px;

                                @include g.web2PageOverrides {
                                    stroke: var(--base-contrast-accent, #0000eb);
                                }

                                @include g.bp-max(sm) {
                                    width: 12px;
                                    min-width: 12px;

                                    @include g.web2PageOverrides {
                                        width: 16px;
                                        min-width: 16px;
                                        height: 16px;
                                    }
                                }
                            }

                            .getButton {
                                margin-top: 15px;
                            }
                        }

                        &.rowHead {
                            .firstColSpacing {
                                padding-right: 10%;

                                @include g.bp-max(md) {
                                    padding-right: 0;
                                }
                            }

                            .plansAtaGlance {
                                width: 219px;
                                max-width: 219px;

                                @include g.bp-max(md) {
                                    width: unset;
                                    max-width: 105px;
                                }

                                @include g.bp-max(sm) {
                                    width: unset;
                                    max-width: 115px;
                                }
                            }

                            .plansAtaGlanceSpacing {
                                padding-right: 11%;
                                padding-top: 0;
                            }

                            th {
                                display: flex;
                                justify-content: center;
                                align-items: flex-start;
                                @include g.font-size(28px);
                                line-height: 1.1;
                                font-weight: 900;
                                text-align: left;
                                border-right: 1px solid g.$blue-ultra-light;
                                flex: 1;
                                height: 60px;
                                border: none;
                                width: 210px;
                                max-width: 210px;
                                padding-top: 22px;

                                @include g.bp-max(md) {
                                    width: 111px;
                                    max-width: 111px;
                                    @include g.font-size(18px);
                                    line-height: 1.2;
                                }

                                &:first-child {
                                    width: 40%;
                                    max-width: 40%;
                                    justify-content: flex-start;

                                    &.smallerAndroidPhone {
                                        width: 35%;
                                        max-width: 35%;
                                    }

                                    &.smallerPhonesBeforeXR {
                                        padding-right: 9%;
                                    }
                                }

                                .planHeaderWrapper {
                                    display: flex;
                                    flex-direction: column;

                                    .gridColumnTitle {
                                        font-weight: 900;
                                        color: var(--base-contrast, #202020)
                                    }

                                    &.smallerPhonesBeforeXRDiv {
                                        min-width: 113px;
                                        max-width: 113px;
                                    }

                                    .colPrice {
                                        .offerSpan {
                                            display: block;
                                            @include g.font-size(16px);
                                            @include g.set-font-weight('bold');
                                            line-height: 1.3;
                                            text-align: left;
                                            color: var(--base-contrast, #202020);

                                            @include g.bp-max(md) {
                                                @include g.font-size(12px);
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.rowPrice {
                            background: var(--white);

                            th {
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-end;
                                align-items: flex-start;
                                padding-bottom: 0;

                                p {
                                    padding-bottom: 15px;

                                    @include g.font-size(12px);

                                    @include g.bp(md) {
                                        @include g.font-size(14px);
                                    }

                                    a {
                                        color: g.$black;

                                        @include g.web2PageOverrides {
                                            color: var(--base-contrast, #202020)
                                        }
                                    }
                                }
                            }

                            td {
                                display: flex;
                                flex-direction: column;
                                padding: 0;
                                row-gap: 16px;
                                border: none;

                                &.bestPlanCol {
                                    background: g.$off-white;

                                    @include g.web2PageOverrides {
                                        background: var(--base-surface, #f7f7f7);
                                    }
                                }

                                .offerSpan {
                                    display: block;
                                    @include g.font-size(16px);
                                    @include g.set-font-weight('bold');
                                    line-height: 1.3;
                                    text-align: center;
                                    color: g.$raspberry;

                                    @include g.web2PageOverrides {
                                        color: var(--base-contrast, #202020);
                                    }

                                    @include g.bp-max(md) {
                                        @include g.font-size(12px);
                                    }
                                }
                            }

                            .colPrice {
                                flex-direction: column;
                                @include g.font-size(16px);
                                @include g.set-font-weight('book');
                                line-height: 1.3;
                                color: g.$black;
                                margin-bottom: 0;

                                @include g.bp-max(md) {
                                    @include g.font-size(12px);
                                }

                                strong {
                                    display: block;
                                    @include g.set-font-weight('black');
                                    @include g.font-size(16px);
                                    line-height: 1.1;
                                    max-width: 76px;
                                    margin: 0 auto;

                                    @include g.bp(md) {
                                        line-height: 1.25;
                                        max-width: unset;
                                    }

                                    @include g.bp-max(md) {
                                        @include g.font-size(12px);
                                    }
                                }
                            }
                        }
                    }

                    .spacerCol {
                        margin-right: 39px;

                        @include g.bp-max(lg) {
                            margin-right: 9px;
                        }

                        @include g.bp-max(md) {
                            margin-right: 9px;
                        }
                    }

                    .firstColSpacing {
                        padding-right: 10%;

                        @include g.bp-max(md) {
                            padding-right: 0;
                        }
                    }

                    .headingwithDesc {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .headingDescription {
                            @include g.font-size(14px);
                            @include g.set-font-weight('book');
                            text-align: left;
                            line-height: 1.35;
                            color: g.$black;
                        }
                    }

                    .topBorderRadius {
                        border-top-left-radius: 16px;
                        border-top-right-radius: 16px;
                    }

                    .seeText {
                        @include g.font-size(16px);
                        @include g.set-font-weight('book');
                        line-height: 1.3;
                        color: g.$black;

                        @include g.bp-max(lg) {
                            @include g.font-size(14px);
                        }

                        @include g.bp-max(md) {
                            @include g.font-size(12px);
                        }
                    }

                    .offerText {
                        @include g.font-size(16px);
                        @include g.set-font-weight('bold');
                        line-height: 1.3;

                        @include g.bp-max(lg) {
                            @include g.font-size(14px);
                        }

                        @include g.bp-max(md) {
                            @include g.font-size(12px);
                        }
                    }

                    .ctaButton {
                        text-transform: none;
                        @include g.font-size(12px);
                        @include g.set-font-weight('bold');
                        line-height: 1.5;
                        padding: 13px 40px;

                        @include g.bp-max(lg) {
                            padding: 13px 30px;
                        }

                        @include g.bp-max(sm) {
                            padding: 13px 35px;
                        }
                    }
                }
            }
        }
    }
}