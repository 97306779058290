@use '@/styles/global' as g;

.mainWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    gap: 16px;
    max-width: 756px;
    width: 100%;
    border-radius: 16px;

    .planHeading {
        display: block;
        margin-bottom: 8px;
        @include g.font-size(32px);
        line-height: 1.12;
        font-weight: 900;

        @include g.web2PageOverrides {
            color: var(--base-contrast, #202020)
        }

        @include g.bp-max(lg) {
            @include g.font-size(30px);
            line-height: 1.13;
        }

        @include g.bp-max(sm) {
            @include g.font-size(24px);
            line-height: 1.12;
        }
    }

    .plancardWrapper {
        display: flex;
        flex-direction: row;
        gap: 24px;
        width: 100%;

        @include g.bp-max(lg) {
            flex-direction: column;
        }

        .cardHeading {
            display: block;
            margin-bottom: 8px;
            @include g.font-size(24px);
            line-height: 1.12;
            font-weight: 900;

            @include g.web2PageOverrides {
                color: var(--base-contrast, #202020)
            }
        }

        .planCard1 {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 50%;
            border-radius: 24px;
            padding: 33.5px 16px 22.5px 24px;
            background-color: g.$off-white;

            @include g.web2PageOverrides {
                background: var(--base-surface, #f7f7f7);
            }

            @include g.bp-max(lg) {
                width: 100%;
            }

            .planCardMarketingList {
                margin-top: 17px;
                margin-bottom: 0;
                row-gap: 12px;

                @include g.bp-max(lg) {
                    row-gap: 16px;
                }

                @include g.web2PageOverrides {
                    li svg {
                        stroke: var(--base-contrast-accent, #0000eb);
                    }
                }

                li p {
                    @include g.font-size(14px);
                    @include g.set-font-weight('book');
                    line-height: 1.35;

                    b {
                        @include g.set-font-weight('bold');
                    }
                }
            }

            .radioButtonWrapper {
                display: flex;
                gap: 11px;
            }

            .bestValue {
                display: inline-flex;
                align-items: center;
                position: relative;
                bottom: 4px;
                height: 16px;
                padding: 0px 4px;
                border-radius: 4px;
                background: g.$raspberry;
                color: g.$white;
                @include g.font-size(10px);
                @include g.set-font-weight('book');
                line-height: 1;
                text-transform: uppercase;
                margin-left: 4px;

                @include g.web2PageOverrides {
                    color: var(--base-contrast, #202020);
                    background: #ffd217;
                }
            }
        }

        .planCard2 {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 50%;
            border-radius: 24px;
            padding: 33.5px 16px 22.5px 24px;
            background-color: g.$off-white;

            @include g.web2PageOverrides {
                background: var(--base-surface, #f7f7f7);
            }

            @include g.bp-max(lg) {
                width: 100%;
            }

            .planCardMarketingList {
                margin-top: 16px !important;
                row-gap: 12px;

                @include g.bp-max(lg) {
                    row-gap: 16px;
                }

                @include g.web2PageOverrides {
                    li svg {
                        stroke: var(--base-contrast-accent, #0000eb);
                    }
                }

                li p {
                    @include g.font-size(14px);
                    @include g.set-font-weight('book');
                    line-height: 1.35;

                    b {
                        @include g.set-font-weight('bold');
                    }
                }
            }
        }
    }
}

.textContentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .headlineStyles {
        @include g.font-size(16px);
        @include g.set-font-weight('bold');
        line-height: 1.31;
        color: g.$black;
        width: fit-content;

        @include g.web2PageOverrides {
            color: var(--base-contrast, #202020)
        }
    }

    .descriptionTextStyles {
        @include g.font-size(12px);
        @include g.set-font-weight('book');
        color: g.$black;
        line-height: 1.5;

        @include g.web2PageOverrides {
            color: var(--base-contrast, #202020)
        }
    }

    .descriptionPrice {
        @include g.set-font-weight('bold');
    }

    .descriptionOffer {
        @include g.set-font-weight('bold');
        text-decoration: underline;
    }
}

.iconWrapper {
    position: relative;
    width: 40px;
    height: 40px;
}

.icon {
    position: absolute;
    width: 100%;
    height: 100%;
}

.button {
    text-transform: none;
    padding: 12px 24px;
    margin-top: 18px;
    max-width: none;
}

.headingDescription {
    @include g.font-size(16px);
    @include g.set-font-weight('book');
    line-height: 1.5;

    @include g.web2PageOverrides {
        color: var(--base-contrast, #202020)
    }

    @include g.bp-max(sm) {
        @include g.font-size(14px);
    }
}

.titleHeadingDescription {
    display: block;
    @include g.font-size(16px);
    @include g.set-font-weight('bold');
    line-height: 1.31;

    @include g.web2PageOverrides {
        color: var(--base-contrast, #202020)
    }
}

.planCardParagraph {
    @include g.web2PageOverrides {
        color: var(--base-contrast-subtle, #646464);
    }
}

.optionalAddOns {
    @include g.font-size(16px);
    @include g.set-font-weight('bold');
    line-height: 1.31;

    @include g.web2PageOverrides {
        color: var(--base-contrast, #202020)
    }
}

.optionalItemWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    gap: 24px;

    @include g.web2PageOverrides {
        color: var(--base-contrast, #202020)
    }
}

.optionalItems {
    display: flex;
    justify-content: space-between;
    padding: 0 22px;
}

.optionalItemHeading {
    @include g.font-size(14px);
    @include g.set-font-weight('bold');
    line-height: 1.35;
}

.iconAndText {
    display: flex;
    gap: 14px;
    margin-top: 16px;
}

.inputStyles {
    display: inline-block;
    appearance: none;
    -webkit-appearance: none;
    position: relative;
    width: 24px;
    height: 24px;
    border: 2px solid g.$gray-dark;
    border-radius: 50%;
    margin-top: 8px;
    cursor: pointer;

    @include g.web2PageOverrides {
        border: 2px solid var(--base-contrast-accent, #0000eb);
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 14px;
        height: 14px;
        background-color: transparent;
        border-radius: 50%;
    }

    &:checked {
        &::after {
            background-color: g.$black;

            @include g.web2PageOverrides {
                background: var(--base-contrast-accent, #0000eb);
            }
        }
    }
}