@use '@/styles/global' as g;

.beltContainer {
    padding-top: 32px;
    padding-bottom: 32px;

    @include g.bp(md) {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    p.beltContainerHeadline {
        margin-bottom: 8px;
    }

    &LongDesc {
        p {
            line-height: g.calculate-rem(24px);
        }

        margin-bottom: 8px;
    }

    &ShortDesc,
    &LongDesc {

        a,
        p a {
            color: inherit;
            @include g.web2PageOverrides {
                color: inherit;
            }
        }

        p,
        span,
        a,
        * {
            margin-bottom: 0;
        }
    }

    .beltContainerButton {
        margin-top: 24px;

        @include g.bp(md) {
            margin-top: 0;
        }
    }
}