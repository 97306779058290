@use '@/styles/global' as g;

.wrapper {
    height: auto;
}

.cardWrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.packageCard {
    padding: var(--card-padding, 32px);
    border: var(--border);
    border-color: g.$gray-light;
    background: g.$white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include g.web2PageOverrides {
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;

        &:not(.promoted) {
            border-top-left-radius: 24px;
            border-top-right-radius: 24px;
        }
    }

    @include g.bp(md) {
        height: 100%;
    }

    .packageCost {
        display: inline;
        margin-bottom: 2px;
        @include g.font-size(44px);

        &.flat {
            color: g.$raspberry;
            @include g.font-size(28px);
        }

        @include g.web2PageOverrides {
            display: inline-block;
            margin-bottom: 0;
            color: var(--base-contrast-accent);
        }
    }

    &.singleCard {
        .cardContent {
            @include g.bp(md) {
                display: flex;
                column-gap: 36px;

                .cardContentLeft {
                    width: 44%;
                }

                .cardContentRight {
                    width: 47%;
                }
            }
        }

        .ctaButton {
            @include g.bp(md) {
                width: 44%;
            }
        }
    }

    &.mobileShort {
        .cardContentRight {
            display: none;

            @include g.bp(md) {
                display: block;
            }
        }

        .ctaButton {
            margin-top: 2px;

            @include g.bp(md) {
                margin-top: 32px;
            }
        }
    }
}

.floatTop {
    @include g.bp(md) {
        position: absolute;
        top: -31px;
    }

    @include g.web2PageOverrides {
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
    }
}

p.packageCostContainer {
    margin-bottom: 16px;
}

.offerIcons {
    margin-bottom: 16px;
}

p.packageCostSubtext {
    color: g.$gray-dark;
    @include g.font-size(12px);
    line-height: 1.5;
}

.packageContentTop {
    margin-bottom: 24px;

    &.seperator {
        padding-bottom: 24px;
        margin-bottom: 8px;
        border-bottom: 1px solid g.$gray-medium;
    }
}

p.packageCopy {
    margin-bottom: 20px;
}

.promoted {
    .packageCost {
        color: g.$raspberry;
    }
}

.fullWidth {
    width: 100%;
}

.planCardMarketingList {
    li img {
        align-self: flex-start;
        margin-top: 8px;
    }
}

.cardWrapper .packageCard ul.planCardCheckmarkBullet {
    li svg {
        width: 14px;
        min-width: 14px;
        margin-top: 6px;
    }
}
