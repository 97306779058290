@use '@/styles/global' as g;

.tableHeader {
    width: 100%;
    display: flex;
    background-color: g.$blue-light;
    @include g.font-size(12px);
    padding: 0 20px;
    align-items: center;
    justify-content: space-between;
    height: 34px;

    @include g.web2PageOverrides {
        background: var(--base-surface-strong, #f0f0f0);
        border-radius: var(--rounding-medium, 16px);
    }

    div:nth-child(1) {
        display: flex;

        @include g.bp(md) {
            display: none;
        }
    }

    div:nth-child(2) {
        display: none;

        @include g.bp(md) {
            display: flex;
            flex-basis: 200px;
        }
    }

    div:nth-child(3) {
        display: none;

        @include g.bp(md) {
            display: flex;
            flex-grow: 1;
        }

        @media (min-width: 0) and (max-width: 374px) {
            font-size: 0;
        }
    }

    div:nth-child(4) {
        flex-basis: 118px;
        text-align: center;

        @include g.bp(lg) {
            flex-basis: 80px;
        }
    }
}
