@use '@/styles/global' as g;

.userEngagementContainer {
    width: 100%;
    padding: 0;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    text-decoration: none;
    background-color: g.$white;

    @include g.web2PageOverrides {
        border-radius: var(--rounding-large, 24px);
        padding: 24px;

        @include g.bp(md) {
            padding: 0px;
        }
    }

    &:before {
        content: '';
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        position: absolute;
        background-color: g.$white;
        -ms-transform: translate3d(-50%, -50%, 0);
        transform: translate3d(-50%, -50%, 0);
        box-shadow: g.$active-shadow;
        -ms-transition:
            width 0.3s ease-in-out,
            height 0.3s ease-in-out;
        transition:
            width 0.3s ease-in-out,
            height 0.3s ease-in-out;

        @include g.web2PageOverrides {
            border-radius: var(--rounding-large, 24px);
        }
    }

    img {
        position: relative;
        width: 100%;
        height: auto;
        display: block;
        margin-right: 0;
    }

    .userEngagementHeading {
        margin-bottom: 4px;
    }

    .userEngagementContent.md {
        width: 100%;

        @include g.bp(md) {
            width: unset;
            max-width: 532px;
        }

        @include g.bp(lg) {
            width: unset;
            max-width: 664px;
        }
    }

    .userEngagementContent.spotlight {
        width: 100%;
        display: block;
        padding: 16px 0 0 0;

        .spotlightHeading {
            width: 100%;
            display: inline-flex;
            align-items: flex-end;
            @include g.font-size(16px);
            @include g.set-font-weight('bold');
            line-height: 24px;
        }

        .spotlightSubHeading {
            color: g.$gray-dark;
            @include g.font-size(14px);
            @include g.set-font-weight('light');
            line-height: 22px;
        }

        @include g.molecule-spacing-simple();
    }

    .userEngagementContent {
        padding: 24px;
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        position: relative;
        width: 100%;

        @include g.web2PageOverrides {
            padding: 24px 0px 0px;

            @include g.bp(md) {
                padding: 24px;
            }
        }

        span:first-of-type {
            @include g.font-size(14px);
            @include g.set-font-weight('book');
            text-transform: none;
            margin-bottom: 8px;

            @include g.bp(md) {
                margin-bottom: 4px;
            }
        }

        h3 {
            margin-bottom: 8px;

            @include g.bp(md) {
                margin-bottom: 4px;
            }
        }
    }

    .userEngagementCtaWrapper {
        padding: 0px;

        button {
            margin-top: 0px;
        }
    }

    .primaryButton,
    .secondaryButton {
        margin-top: 24px;
    }

    &.large {
        .userEngagementLegal {
            @include g.font-size(12px);
            line-height: 18px;
            color: g.$gray-dark;
            margin-bottom: 0;
            width: 100%;

            @include g.bp(lg) {
                margin-top: 0;
                order: 1;
            }
        }

        .userEngagementEyebrow {
            @include g.font-size(14px);
        }

        .userEngagementHeading {
            @include g.bp(lg) {
                width: calc(9 / 12 * 100%);
                margin-bottom: 20px;
            }
        }

        .userEngagementCtaWrapper {
            width: 100%;

            @include g.bp(lg) {
                width: calc(3 / 12 * 100%);
                justify-content: flex-start;
            }

            a {
                margin-top: 10px;

                @include g.bp(lg) {
                    margin-top: 0;
                }

                @include g.bp(md) {
                    max-width: inherit;
                }
            }

            button {
                margin-top: 0px;
            }
        }
    }

    &.medium {
        display: flex;
        text-decoration: none;

        &:not(:last-of-type) {
            margin-bottom: 8px;
        }

        &:hover,
        &:focus {
            @include g.bp(md) {
                z-index: 1;

                &:before {
                    width: calc(100% + 16px);
                    height: calc(100% + 16px);
                    box-shadow: g.$hover-shadow;
                }
            }
        }

        @include g.bp(md) {
            transition-property: width, margin-bottom, border-width, box-shadow;
            transition-duration: 0.25s;
            border-top: 0 solid g.$white;
            border-bottom: 0 solid g.$white;
            flex-direction: row;
            padding: 24px 32px 24px 24px;

            &:not(:last-of-type) {
                margin-bottom: 4px;
            }

            &:not(:first-of-type) {
                margin-top: 4px;
            }
        }

        img {
            @include g.bp(md) {
                max-width: 264px;
                display: block;
                margin-right: 24px;
            }
        }

        .userEngagementContent {
            z-index: 1;
            display: block;
            margin: 0px;

            @include g.bp(md) {
                padding: 0;
            }
        }

        .userEngagementCta {
            margin-top: 16px;
            display: inline-block;
            width: 100%;

            a {
                margin-top: 0;
            }
        }

        // .userEngagementLabel {
        //   line-height: 1.57;
        // }

        .userEngagementHeading {
            @include g.font-size(18px);
            @include g.set-font-weight('bold');
            white-space: normal;
            color: g.$black;
            line-height: 1.2;
            display: block;
        }

        .userEngagementLegal {
            color: g.$gray-dark;
            @include g.font-size(12px);
            line-height: 1.5;
            margin-bottom: 0px;
        }

        .userEngagementCta {
            color: g.$blue-secondary;
            text-decoration: underline;
            text-underline-offset: 3px;
            text-decoration-thickness: 2px;

            @include g.web2PageOverrides {
                color: var(--base-contrast-accent, #0000eb);
            }
        }
    }

    &.spotlight {
        background-color: transparent;

        &:before {
            box-shadow: none;
            background-color: transparent;
        }
    }
}

.imageStyles {
    @include g.web2PageOverrides {
        border-radius: var(--rounding-large, 24px);
    }
}

.imageTopBorder {
    @include g.web2PageOverrides {
        border-radius: var(--rounding-large, 24px);

        @include g.bp(md) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}