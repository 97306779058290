@use '@/styles/global' as g;

.headline {
    margin-bottom: 4px;

    &:last-child {
        margin-bottom: 0;
    }
}

.dateAndSource {
    @include g.font-size(12px);
    color: var(--gray-charcoal);
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0;
    }
}

.videoBlock {
    margin-bottom: 24px;

    @include g.bp(md) {
        margin-bottom: 0;
    }
}

.button {
    margin-top: 16px;
}

.link {
    margin-top: 16px;
    display: block;
}