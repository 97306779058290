@use '@/styles/global' as g;

// Added group spacing styles since the OfferComparison component was moved to be a standalone component (in standardPage).
.container {
    @include g.group-spacing;
}

.offersTable {
    border: 0;
    border-spacing: 0;

    h2 {
        margin: 6px 6px 24px 6px;
    }

    .offerTitle {
        color: g.$raspberry;
        margin-bottom: 0;
        @include g.font-size(24px);
        line-height: 1.2;
        @include g.set-font-weight('black');
    }

    tr {
        display: flex;
        justify-content: center;
        grid-column-gap: 12px;

        @include g.bp(md) {
            grid-column-gap: 24px;
        }

        th,
        td {
            flex: 1;
            text-align: center;
            padding: 0 6px;
        }

        th {
            margin-bottom: 24px;

            @include g.bp(lg) {
                margin-bottom: 28px;
            }
        }

        td {
            border-bottom: 1px solid g.$gray-medium;
            margin-bottom: 24px;
            padding-bottom: 24px;
            display: flex;
            flex-direction: column;
        }

        &:last-child td {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    td.headerContainer {
        :global(span.paragraph) {
            margin-bottom: 0;
        }

        padding: 0 0 24px;

        @include g.bp(md) {
            padding: 0 6px 24px;
        }
    }

    td.featureContainer {
        p {
            margin-bottom: 0;
        }

        :global(span.paragraph) {
            margin-bottom: 8px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .monthlyPrice {
        margin: 0;
    }

    .image {
        margin-bottom: 24px;
    }

    a.featureButton {
        margin: 8px auto;

        @include g.bp-max(md) {
            max-width: 152px;
            height: 48px;
            line-height: 1.4;

            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .blankFeature {
        justify-content: center;
    }
}

.subHeadline {
    text-align: center;
    line-height: 1.6;
}