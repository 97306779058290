@use '@/styles/global' as g;

$spacing-small: 24px;
$spacing-medium: 32px;
$spacing-large: 48px;

@keyframes fadein {
    0% {
        opacity: 0;
        transform: translateX(32px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.wrapper {
    width: 100%;

    &::before {
        z-index: 1;
    }

    &.darkTheme {
        *:not(button, .ctaButton) {
            color: var(--white);
        }

        & .ctaWrapper a:not(.customTheme):hover,
        & .ctaWrapper a:not(.customTheme):hover::before {
            background: transparent;
            color: var(--white);
            border-color: var(--white);
        }

        .whiteText {
            color: var(--white);
        }
    }

    &.fullHeightTheme {
        .gridContainer {
            @include g.bp(lg) {
                min-height: 100vh;
            }
        }

        .contentWrapper {
            padding-bottom: $spacing-large;
        }
    }

    &.hero {
        &.fullHeightTheme {
            .gridContainer {
                @include g.bp(lg) {
                    min-height: calc(100vh - map-get(g.$nav-height, md));
                }
            }
        }
    }

    &.ctaSideBySide {
        .ctaWrapper {
            @include g.bp(md) {
                display: flex;
                column-gap: 24px;
            }

            a {
                @include g.bp(md) {
                    margin-top: 0;
                }
            }
        }
    }

    .contentWrapper {
        padding-top: $spacing-small;
        padding-bottom: $spacing-small;
        position: relative;
        z-index: 2;

        @include g.bp(large) {
            padding-top: $spacing-large;
            padding-bottom: $spacing-large;
        }

        > *:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    .foregroundImage {
        picture {
            width: 100vw;
            display: block;
            margin-left: -16px;
            position: relative;
            z-index: 1;

            @include g.bp(sm) {
                margin-left: -18px;
            }

            @include g.bp(lg) {
                width: 100%;
                margin: 0;
            }

            img {
                object-fit: contain;
                height: 100%;

                @include g.bp(sm) {
                    max-height: 70vh;
                }

                @include g.bp(lg) {
                    max-height: none;
                }
            }
        }
    }

    .negativeMargin {
        margin-bottom: unset;

        @include g.bp(lg) {
            margin-bottom: -56px;
        }

        @include g.bp(xl) {
            margin-bottom: -72px;
        }
    }

    .imagePartner {
        margin-bottom: 16px;
        width: 48%; // ~ 6-col

        @include g.bp(large) {
            width: 30%; // ~ 2-col
        }
    }

    .headline {
        margin-bottom: 8px;

        * {
            margin-bottom: 0;
        }

        &.animatedHeadline {
            span {
                opacity: 0;
                animation-duration: 1s;
                animation-name: fadein;
                animation-fill-mode: forwards;

                &:nth-of-type(1) {
                    animation-delay: 1s;
                }

                &:nth-of-type(2) {
                    animation-delay: 2.2s;
                }

                &:nth-of-type(3) {
                    animation-delay: 3.5s;
                }
            }
        }
    }

    .headerSection {
        margin-bottom: $spacing-small;

        @include g.bp(lg) {
            margin-bottom: $spacing-medium;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }

    .offer {
        margin-bottom: $spacing-small;

        @include g.bp(lg) {
            margin-bottom: $spacing-medium;
        }

        .offerDetails {
            margin-bottom: 16px;
        }
    }

    .marketingList {
        margin-bottom: $spacing-small;
        max-width: 392px;
    }

    .descriptionBlock,
    .marketingCopy {
        margin-bottom: $spacing-small;
        @include g.font-size(12px);
        @include g.set-font-weight('book');

        @include g.bp(lg) {
            @include g.font-size(14px);
        }
    }

    .descriptionBlock {
        span {
            @include g.font-size(14px);
        }
    }

    .ctaWrapper {
        margin-bottom: $spacing-small;

        a:first-child {
            @include g.bp(md) {
                margin-top: 0;
            }
        }

        .imagesWrapperStyles {
            display: flex;
            gap: 10px;
            margin-bottom: 8px;
        
            .staticImageStyles,
            .qrCodeStyles {
                width: 96px;
                height: 96px;
            }
        }
        .bodyTextStyles {
            @include g.font-size(12px);
            line-height: 1.6;
            margin: 16px 0px;
        }

        .ctaButtonWrapper {
            display: none;

            @include g.bp(md) {
                display: block;
                min-height: 56px;
            }
        }
    }

    .marginTopCta {
        margin-top: 32px;
    }

    .slider {
        margin-bottom: 16px; // Plus pagination dots spacing (total 24px)

        @include g.bp(lg) {
            margin-bottom: $spacing-small;
        }
    }

    .scrollStartRef {
        position: absolute;
        top: 0;
    }

    .backgroundVideo {
        height: 100%;
        top: 0;
        right: 0;

        @include g.bp(lg) {
            position: absolute;
            width: 75%;
            height: auto;
            min-height: 100%;
            padding-top: 31.25%;
        }
    }

    &.hasVideo {
        @include g.bp(lg) {
            min-height: 31.25vw;
            display: flex;
        }
    }

    &.videoOnLeft {
        &::before {
            @include g.bp(lg) {
                transform: scaleX(-1);
            }
        }

        .backgroundVideo {
            left: 0;
        }
    }
}
