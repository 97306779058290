@use '@/styles/global' as g;

.modalWrapper {
    .modal {
        >div:first-of-type {
            background-image: url('https://siriusxm.com/content/dam/sxm-com/sales-flows/trial-eligibility/TrialEligibility-BG-Mobile.jpg');
            background-position: center;
            background-size: cover;
            opacity: 0;
            transition: opacity var(--te-transition-duration, 0.5s) ease-in;

            @include g.bp(lg) {
                background-image: url('https://siriusxm.com/content/dam/sxm-com/sales-flows/trial-eligibility/TrialEligibility-BG-Desktop.jpg');
            }
        }

        &.baseAnimation>div:first-of-type {
            opacity: 1;
        }

        &.exitAnimation>div:first-of-type {
            opacity: 0;
        }

        &:has(sxm-ui-form-field-error)>div:first-of-type::before {
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 55%;
            background-color: g.$black;
        }

        >dialog {
            background-color: transparent;
            top: 0;
            transform: none;
            overflow: visible;
            overflow-y: auto;
        }
    }
}

.logoContainer {
    display: none;

    @include g.bp(lg) {
        display: flex;
    }

    align-items: center;
    background-color: g.$white;
    background-color: transparent;
    height: 64px;
    position: relative;
    z-index: 1;

    .logo {
        width: 136px;
        display: block;
        margin: 0 auto 0 0;

        svg {
            fill: g.$white;
        }
    }
}

.modalContent {
    width: 100%;
    background-color: transparent;
    padding: 16px 0 40px;
    overflow: hidden;
    min-height: 100vh;

    @include g.bp(lg) {
        padding-top: 32px;
    }

    .modalText,
    .widgetContainer button {
        color: g.$white;
    }

    .textButton {
        font-size: inherit;
        font-weight: inherit;
        @include g.link-underline-properties;
    }

    .widgetContainer {
        margin-top: 24px;
        opacity: 0;
        transition: opacity var(--te-transition-duration, 0.3s) ease-out;

        @include g.bp(lg) {
            margin-top: 40px;
        }

        .baseAnimation & {
            opacity: 1;
        }

        .exitAnimation & {
            opacity: 0;
        }
    }
}

.animatedSection {
    position: relative;
    top: 35vh;
    transition: all var(--te-transition-duration, 0.3s) ease-out;

    @include g.bp(lg) {
        top: 40vh;
    }

    .baseAnimation & {
        top: 0;
    }

    .exitAnimation & {
        top: 50vh;
        opacity: 0;
    }
}

.title {
    text-align: center;
}

.closeButton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
    transition: all 0.4s ease-in-out;
    opacity: 0;
    position: relative;

    .baseAnimation & {
        opacity: 1;
    }

    .exitAnimation & {
        opacity: 0;
    }

    @include g.bp(lg) {
        margin-bottom: 32px;
    }

    button {
        transition: all 0.3s ease-in-out;
        border-radius: 100px;
        border: 1.5px solid g.$gray-semi-dark;
        width: 40px;
        height: 40px;
    }

    svg {
        fill: g.$gray-semi-dark;
        width: 24px;
        height: 24px;
        transition: all 0.3s ease-in-out;
    }

    &:hover button {
        border-color: g.$white;

        svg {
            fill: g.$white;
        }
    }
}
